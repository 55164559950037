import * as React from "react";
import { useState, useEffect, useRef } from "react";
// import "./Testimonial.css";
// @ts-ignore
import star from "../../assets/star.svg";

interface Testimonial {
  name: string;
  stars: number;
  comment: string;
}

interface Testimonials {
  testimonials: Testimonial[];
  slideTs: number;
}

export function Testimonial(props: Testimonial) {
  function createMarkup() {
    return { __html: props.comment };
  }
  return (
    <div className="block">
      <div className="stars">
        <div></div>
        <div>
          <img src={star} alt="star" />
        </div>
        <div>
          <img src={star} alt="star" />
        </div>
        <div>
          <img src={star} alt="star" />
        </div>
        <div>
          <img src={star} alt="star" />
        </div>
        <div>
          <img src={star} alt="star" />
        </div>
        <div></div>
      </div>
      <div className="author">{props.name}</div>
      <div
        className="description"
        dangerouslySetInnerHTML={createMarkup()}
      ></div>
    </div>
  );
}

export function Testimonials(props: Testimonials) {
  const { testimonials } = props;

  let [count, setCount] = useState(1);

  useInterval(() => {
    // Your custom logic here
    let counter = count;
    if (counter === testimonials.length) {
      count = 0;
    }
    setCount(count + 1);
  }, 6000);
  return (
    <div>
      <Testimonial
        name={testimonials[count - 1].name}
        stars={testimonials[count - 1].stars}
        comment={testimonials[count - 1].comment}
      ></Testimonial>
    </div>
  );
}

function useInterval(callback: any, delay: any) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
    return;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback) {
        (savedCallback as any).current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
}
