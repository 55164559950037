import * as React from "react";
import { render } from "react-dom";

import { Testimonials } from "./components/testimonial/Testimonial";

const testimonials = [
  {
    name: "Nathalie Dijon",
    stars: 5,
    comment:
      "<p>Un grand merci à toute l'équipe de Grain de Cél !<br />Du début jusqu'à la fin, ils ont su être à notre écoute et nous accompagner.<br />En véritable professionnelle, l'équipe de Grain de Cél a réussi à faire de notre mariage un moment magique. Du vin d'honneur jusqu'au brunch du lendemain, tout était absolument somptueux.<br />Le service était impeccable et les maîtres d'hôtel sont souriants, avenants et efficaces. Ils connaissent leur métier et le maitrisent parfaitement.<br />Le cuisinier est juste un génie. Une mention spéciale au pavé de veau et au gratin dauphinois qui étaient exceptionnels. Mais nous n'oublions pas le reste...<br />Nos familles et amis ont été charmés par une cuisine délicate, harmonieuse et belle (au goût et à la vue).<br />Si la « partie traiteur » dans un mariage est importante, voire cruciale, alors ils ont pu relever ce défi avec brio. Chapeau bas aux artistes !<br /></p>",
  },
  {
    name: "Lucie",
    stars: 5,
    comment:
      "<p>Mariage du 22/08. Tout était absolument parfait. Nous sommes plus qu'enchantés d'avoir collaborer avec alexandre qui nous a guidé dans nos préparatifs avec beaucoup de sympathie. Nos invités ont été bluffés par les plats tous plus délicieux les uns que les autres mais également par le service qui était incroyablement professionnel. Nous avons été chouchoutés. Ils nous ont fait rêver. Merci à vous d'avoir participé au succès de notre journée. Bref, futurs mariés, foncez les yeux fermés!</p>",
  },
  {
    name: "Véronique",
    stars: 5,
    comment:
      "<p>On a été super contents de la prestation de Grain de Cél Traiteur. Très présents pendant les préparatifs pour répondre à toutes nos questions, on était sereins le jour J. Et le jour J, Les maîtres d'hôtel ont été parfaits : on nous a apporté à manger pendant tout le cocktail, toute l'organisation du cocktail et repas a été très bien menée et surtout c'était très bon ! Un grand merci pour la fondue au chocolat qui était excellente !</p>",
  },
  {
    name: "Catherine Hirel",
    stars: 5,
    comment:
      "<p>Alexandre a été un excellent interlocuteur, il a su comprendre nos attentes et adapter le menu et le service pour rendre le dîner convivial, tel que nous le souhaitions. Nous avions choisi une formule buffet barbecue qui a été très appréciée des convives. Nous recommandons vivement !</p>",
  },
  {
    name: "Bobant Clémence",
    stars: 5,
    comment:
      "<p>Nous avons choisi Grain de Cél pour notre réception de mariage le 29 août au Relais de Neuville. Alexandre nous a accompagnés durant la préparation de notre réception.<br />Le cocktail, le repas, ainsi que la qualité du service ont été exceptionnels ! Originalité et qualité étaient au rendez-vous entre amuse-bouche, plats et desserts. Le service est également d'une qualité irréprochable avec des serveurs prenant soin des mariés, ce qui est très agréable.<br />Seul petit couac dans l'organisation : problème d'horaire avec le prestataire de matériel le jour J.<br />Si vous recherchez un traiteur de qualité à un prix raisonnable, nous recommandons Grain de Cél pour vos réceptions ! Merci encore à toute l'équipe.</p>",
  },
];

// render(<Counter />, document.getElementById("main"));
// render(<Testimonial
//     name={testimonials[0].name}
//     stars={testimonials[0].stars}
//     comment={testimonials[0].comment}
//   ></Testimonial>, document.getElementById("main"));

render(
  <Testimonials testimonials={testimonials} slideTs={2500}></Testimonials>,
  document.querySelector(".testimonial")
);


